import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser'
const Join = () => {
    const form=useRef()
// im using https://www.emailjs.com/docs/examples/reactjs/ third part libery
    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm('service_4gh00tp', 'template_lk46hit', form.current, '8_9MWeqjo6P0y_5fl')
          .then((result) => {
              console.log(result.text);
          }, (error) => {
              console.log(error.text);
          });
      };
  return (
   <div className="Join" id='join-us'>
    <div className="left-j">
        <hr />
        <div>
            <span className='stroke-text'>READY TO</span>
            <span>LEVEL UP</span>
            </div>
        <div>
        <span>YOUR BODY</span>
            <span className='stroke-text'>WITH US?</span>
            </div>
    </div>
    <div className="right-j">
        <form ref={form} className="email-container" onSubmit={sendEmail}>
            <input type="email" name='user_email' placeholder='Enter your Email address' />
            <button className=' btn btn-j'>Jion Now</button>
        </form>
    </div>
   </div>
  )
}

export default Join