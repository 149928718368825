import React from "react";
import "./Hero.css";
import hero_image from "../../assets/hero_image.png";
import hero_image_back from "../../assets/hero_image_back.png";
import Heart from "../../assets/heart.png";
import Calories from "../../assets/calories.png";
import NumberCounter from "number-counter"
import Header from "../Header/Header";

import { motion } from "framer-motion";
// motion is aniamtion fram work
const Hero = () => {
  const transition = { type: "spring", duration: 3 };
  const mobile=window.innerWidth<=768?true:false
  return (
    <div className="hero" id='home'>
      <div className="blur hero-blur"></div>
      {/* left side */}
      <div className="left-h">
        <Header />
        {/* the best add */}
        <div className="the-best-ad">

          <motion.div
            initial={{ left:mobile?'165' :"238px" }}
            whileInView={{ left: "8px" }}
          transition={{...transition,type:'tween'}}>

          </motion.div>
          <span>The best fitnes club in the town</span>
        </div>
        {/* hero Heading */}
        <div className="hero-text">
          <div>
            <span className="stroke-text">shape </span>
            <span>your</span>
          </div>
          <div>
            <span>Ideal Body</span>
          </div>
          <div className="span">
            <span>
              in here we will help you to shape and build your ideal body and
              live up your life to fullest
            </span>
          </div>
        </div>
        {/* figures */}
        <div className="figures">
          <div>
            <span><NumberCounter end={140} start={100} delay='4' preFix='+'/></span>
            <span>expert coachs</span>
          </div>
          <div>
            <span><NumberCounter end={978} start={967} delay='4' preFix='+'/></span>
            <span>members joined</span>
          </div>
          <div>
            <span><NumberCounter end={56} start={46} delay='4' preFix='+'/></span>
            <span>fitness programs</span>
          </div>
        </div>
        {/* her0 buttons */}
        <div className="hero-button">
          <button className="btn">Get Started</button>
          <button className="btn">Learn More</button>
        </div>
      </div>
      {/* right side */}
      <div className="right-h">
        <button className="btn">Jion Now</button>

        <motion.div 
         initial={{ right: "-1rem" }}
         whileInView={{ right: "4rem" }}
        transition={transition}
       
        className="heart-rate">
          <img src={Heart} alt="" />
          <span>Heart Rate</span>
          <span>16bpm</span>
        </motion.div>
        {/* hero img */}
        <img src={hero_image} alt="" className="hero-image" />
        <motion.img 
         initial={{ right: "15rem" }}
         whileInView={{ right: "22rem" }}
        transition={transition}
        src={hero_image_back} alt="" className="hero-image-back" />
        {/* calories */}
        <motion.div 
         initial={{ right: "37rem" }}
         whileInView={{ right: "28rem" }}
        transition={transition}
        className="calories">
          <img src={Calories} alt="" />
          <div>
            <span>Calories Burned</span>
            <span>220 Kcal</span>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Hero;
